// Check if mobile - add body class.
function mobileCheck() {
	var resolution = jQuery(window).outerWidth();
	if (resolution < 768) {
		var isMobile = true;
		jQuery('body').addClass('mobile');
	} else {
		var isMobile = false;
		jQuery('body').removeClass('mobile');
	}
}

jQuery(window).on("load resize", function() {
	mobileCheck();
});

// Mobile Trigger
jQuery('.mobile-trigger').click(function() {
	var triggerBox = jQuery(this);
	var trigger = jQuery(this).find('svg, i');
	if (jQuery('#splash-nav').is(':visible')){
		triggerBox.removeClass('mob-trig-over');
		jQuery('#splash-nav').fadeOut(400);
		if (jQuery('.cloned-head').is(':visible')) {
			jQuery('.cloned-head').removeClass('cloned-head-over');
		}
		triggerBox.removeClass('menu-close').addClass('menu-open');
		jQuery('body').css('overflow', 'auto');
	} else {
		jQuery('.cloned-head .mobile-trigger').hide();
		jQuery('#splash-nav').fadeIn(400)
			if (jQuery('.cloned-head').is(':visible')) {
				jQuery('.cloned-head').addClass('cloned-head-over');
			}
		
		jQuery('.cloned-head .mobile-trigger').fadeIn(400);
		triggerBox.addClass('mob-trig-over');
		triggerBox.removeClass('menu-open').addClass('menu-close');
		jQuery('body').css('overflow', 'hidden');
		
	}	
});

(function($) {

	$.fn.hasScrollBar = function() {
        return this[0] ? this[0].scrollHeight > this.innerHeight() : false;
    }

	//wak work carousel
	var $worksCarouselContainer = $('.wak_how_it_works');
	var $worksCarousel = $('#works-carousel');
	
	if($worksCarousel.length){
		//repurposed from snook.ca - bootstrap should have this as an option
		function normalizeSlideHeights() {
			$('.carousel.normalise-slide-height').each(function(){
			var items = $('.carousel-item', this);
			// reset the height
			items.css('min-height', 0);
			// set the height
			var maxHeight = Math.max.apply(null, 
				items.map(function(){
					return $(this).outerHeight()}).get() );
			items.css('min-height', maxHeight + 'px');
			})
		}
		$(window).on('load resize orientationchange',  normalizeSlideHeights);

		$worksCarousel.on('slide.bs.carousel', function(event){
			// console.log(event.to);
			$worksCarouselContainer.find('.carousel-number').text('' + (event.to + 1) + '');
		});	
	}

	//look for transparent navigation based on the type of page and if it has the correct hero 
	//which is calculated with php
	var $hasTransparentNav = $('.wak_homepage_hero.child');
	
	if($hasTransparentNav.length){
		
		var $header = $('.desktop-head');
		var elementHeight = $header.outerHeight();
		
		//init fix for reloads or anchors
		if(window.scrollY > elementHeight){
			$header.addClass('solid-bg');
		}

		$(window).on("scroll", function() {
			
			//if scroll is above height of element give bg color
			if(window.scrollY > elementHeight){
			
				$header.addClass('solid-bg');
			} 
			else { //if scroll is below height remove bg color
				$header.removeClass('solid-bg')
			}
		});
	}

	//wak parllax items
	var $parallaxItems = $("[data-parallax-modifier]");

	if($parallaxItems.length){
		$(window).on("scroll", function() {
			
			$parallaxItems.each(function(i){
				
				var parentContainerHeight = $(this).parent().height(),
					parentContainerTop = $(this).parent().offset().top;

				if(window.scrollY < parentContainerHeight && window.scrollY > parentContainerTop){
					var percentTraversed = window.scrollY / parentContainerHeight; //0.1-0.99
					var modifier = $(this).data('parallax-modifier'); //-0.99 - 0.99
					var parallaxAmount = (percentTraversed * modifier) * 100; //want a rounder number
					var finalTranslateY = parallaxAmount + 50; //the elements are already at 50% - although may not always be - set a data attr?
					
					//note the - (negative) in the string value
					$(this).css({transform: 'translateY(-' + finalTranslateY.toFixed(4) + '%)'}); 
					
				}	
			});
		});
	}

	//wak tabbed container
	var $tabbedContainer = $('.wak-tabbed-nav');

	if($tabbedContainer.length){
		$tabbedContainer.each(function(i){
			$(this).find('a').on('click', function(e){
				e.preventDefault();

				var $link = $(this);
				var top = $($link.attr('href')).offset().top - 100;

				if($(window).width() > 768){
					
					top = $($link.attr('href')).offset().top - $('.desktop-head').outerHeight() + 1;
				}

				$tabbedContainer.removeClass('open-mobile-drop');
				$('html, body').animate({
					scrollTop: top
				}, 1000);
			});
		});

		$('.wak-tabbed-nav_mob-hidden-but').on('click', function(e){
			e.preventDefault();
			$tabbedContainer.addClass('open-mobile-drop');
		});
	}

	//wak faqs
	var $wakFAQs = $('.wak_faqs_module');

	if($wakFAQs.length){
		$allWakFAQs = $wakFAQs.find('ul.wak_faqs_module-list li');

		$wakFAQs.find('ul.wak_faqs_module-list li header').on('click', function(e){
			e.preventDefault();
			
			$allWakFAQs.each(function(){
				$(this).removeClass('active');
			});

			$(this).parent().addClass('active');
		});
	}

	//wak animate soundwaves - add class when in view
	var $animateSoundwaves = $('.wak_get_in_touch.has-soundwaves');

	if($animateSoundwaves.length){
		
		var soundwavesTopPos = $animateSoundwaves.offset().top;

		$(window).on("scroll", function() {
			var scrollBottom = window.scrollY + document.documentElement.clientHeight;
			
			if(scrollBottom > soundwavesTopPos){
				$animateSoundwaves.addClass('is-visible');
			}
		});
	}

	var $interactiveFeatures = $('#interactive-features');

	if($interactiveFeatures.length){
		var $svgPoints = $interactiveFeatures.find('.feature-map svg [id^="poi"]');
		var $htmlPoints = $interactiveFeatures.find('ul li header');
		var $audioPlayers = $interactiveFeatures.find('audio');
		var currentID = -1;

		if($('.feature-list ul').hasScrollBar()){
			$('.feature-list ul').addClass('has-scrollbar')	
		}

		function stopAllAudioPlayers(){
			$audioPlayers.each(function(){
				$(this)[0].pause();
			});
		}

		function clearAllHotspots(){
			$svgPoints.removeClass('active');
			$htmlPoints.parent().removeClass('active');
			$audioPlayers.parent().removeClass('playing');
		}

		function scroll_reveal_list_item(targetID) {
			var $list = $('.feature-list ul');
			var listTop = $list.offset().top;
			var currentItemTop = $('.feature-list ul li[data-point-id="' + targetID + '"]').offset().top;
			
			$list.scrollTop(currentItemTop - listTop);
		}

		$svgPoints.each(function(){
			var $this = $(this);

			$this.on('mouseenter', function(e){
				var targetID = $(this).attr('id');
				
				clearAllHotspots();

				$(this).addClass('active');
				$('.feature-list ul li[data-point-id="' + targetID + '"]').addClass('active');
								
				if(targetID !== currentID){
					stopAllAudioPlayers();
					scroll_reveal_list_item(targetID);
				}

				currentID = targetID;
			});
			
		});

		$htmlPoints.each(function(){
			var $this = $(this);

				$this.on('click', function(e){
					e.preventDefault();
					
					if(!$(e.target).is('button')){					
						var targetID = $this.parent().data('point-id');
						
						clearAllHotspots();

						$(this).parent().addClass('active');
						$('.feature-map svg [id="' + targetID + '"]').addClass('active');
					}
				});
		});

		$audioPlayers.each(function(){
			var $parentContainer = $(this).parent();
			var $playPauseButton = $parentContainer.find('button');
			var player = $(this)[0];

			$playPauseButton.on('click', function(e){
				e.preventDefault();

				if(player.duration > 0 && !player.paused){
					player.pause();
					$playPauseButton.toggleClass('playing');
				}
				else {
					player.play();
					$playPauseButton.toggleClass('playing');
				}
			});

			player.onended = function(){
				$playPauseButton.removeClass('playing');
			}
		});
	}

	var $tabbedBenefits = $('.wak_tabbed_benefits');

	if($tabbedBenefits.length){
		var $tabbedBenefitLinks = $tabbedBenefits.find('.wak_tabbed_benefits-nav a');

		$tabbedBenefitLinks.each(function(){
	
			$(this).on("click", function(e){
				e.preventDefault();

				var tabClass = $(this).data('tab-class');
				
				$tabbedBenefitLinks.removeClass('active');
				$tabbedBenefitLinks.parent().removeClass('show-mob');
				$tabbedBenefits.find('.wak_tabbed_benefits-nav').removeClass('open-mobile-drop');

				$(this).addClass('active');
				$(this).parent().addClass('show-mob');

				$tabbedBenefits.find('.wak_tabbed_benefits-content [class^="tab-"]').removeClass('active');
				$tabbedBenefits.find('.' + tabClass).addClass('active');
	
			});
		});

		$tabbedBenefits.find(".mob-hidden-but").on("click", function(e){			
			e.preventDefault();
			$tabbedBenefits.find('.wak_tabbed_benefits-nav').addClass('open-mobile-drop');
		});
	}

	var $thoughtLeaderShip = $('.wak_thought_leadership');

	if($thoughtLeaderShip.length){
		let $buttonPrev = $('#wak-thought-leadership-prev');
		let $buttonNext = $('#wak-thought-leadership-next');
		let $track		= $thoughtLeaderShip.find('.track');
		let $posts		= $thoughtLeaderShip.find('.post');
		let trackWidth	= $posts.length * $($posts[0]).outerWidth();
		let translate	= 0;
		let move = $($posts[0]).outerWidth() * 2;
		
		$buttonPrev.on('click', function(e){
			e.preventDefault();
			
			if(translate >= 0) {
				translate = 0;
			}
			else {
				translate += move;
			}

			moveTrack(translate);

		});

		$buttonNext.on('click', function(e){
			e.preventDefault();
			let remainingVisible = 0;
			let currentTranslate = translate;

			translate -= move;
			remainingVisible = trackWidth + translate;

			if(remainingVisible > 0){
				moveTrack(translate);
			}
			else {
				translate = currentTranslate;
				moveTrack(translate);
			}

		});
		
		function moveTrack(pixels){
			$track.css('transform', 'translateX(' + pixels +'px)');
		}

		$(window).on('resize', function(){
			trackWidth	= $posts.length * $($posts[0]).outerWidth();
			translate	= 0;
			move = $($posts[0]).outerWidth() * 2;

			if($(window).width() >= 768){
				moveTrack(translate);
			}
		});
	}
})( jQuery );

// Render Google Map
jQuery(function() {
	if (jQuery('#gmap').length) {
		

			var lat = jQuery('#gmap').data('lat');
			var lng = jQuery('#gmap').data('lng');
			var title = jQuery('#gmap').data('title');
			var zoom = jQuery('#gmap').data('zoom');
			function initialize() {
			var myLatlng = new google.maps.LatLng(lat, lng);
			var map_canvas = document.getElementById('gmap');
			var map_options = {
			center: new google.maps.LatLng(lat, lng),
			zoom: zoom,
			zoomControl: true,
				scaleControl: true,
				scrollwheel: false,
				disableDoubleClickZoom: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP
			}
			var map = new google.maps.Map(map_canvas, map_options);
			var contentString = '<div class="map-pu-box">' + title + '</div>';

			var infowindow = new google.maps.InfoWindow({
					content: contentString
			});
			var marker = new google.maps.Marker({
					position: myLatlng,
					map: map,
					title: title,
					
			});
			google.maps.event.addListener(marker, 'click', function() {
				//jQuery('.map-overlay-location').addClass('wobble animated');
				//setTimeout(function() {
				//	jQuery('.map-overlay-location').removeClass('wobble animated');
				//}, 1300);
			});
			}
			google.maps.event.addDomListener(window, 'load', initialize);
		
	}

});



// Show comment button when comment form has value

jQuery('textarea#comment').on("change, keyup", function() {
	var count = jQuery("#comment").val().length;
	if (count > 1) {
		jQuery('.single-comment-strip input[type="submit"]').slideDown(300);
	} else {
		jQuery('.single-comment-strip input[type="submit"]').slideUp(300);
	}
});


//Whitepaper verticals drop down
jQuery('input[name="IWant"]').change(function() {
   var value = jQuery(this).val();
   var value = value.toLowerCase(); 
	if (value === 'the voice search report + verticals' || value === 'the overall voice search report + vertical specific reports') {
		jQuery('.verticals-col').slideDown(300);
		jQuery('#subject-input').val('Rabbit & pork Voice search report AND verticals request – Q3 2018');
	} else { 
		jQuery('.verticals-col').slideUp(300);
		jQuery('#subject-input').val('Rabbit & Pork Vertical Voice search request');
    }
});


jQuery('.select-all').click(function() {
	var checkboxes = jQuery('.Verticals').find('input[type="checkbox"]');
	checkboxes.each(function() {
		
			jQuery(this).prop('checked', true);
		
	});
});

jQuery('.deselect-all').click(function() {
	var checkboxes = jQuery('.Verticals').find('input[type="checkbox"]');
	checkboxes.each(function() {
		
			jQuery(this).prop('checked', false);
		
		
	});
})
